// this file is pulled from  https://gist.github.com/wolever/30a34443a9b2982615bbd2e84cd9529e
// in the following thread https://github.com/TanStack/query/discussions/1109#discussioncomment-8148619
import { type QueryClient } from '@tanstack/react-query';

export const SymbolDispose = Symbol('dispose');
type Disposable = { [SymbolDispose]: () => void };

/**
 * Subscribe to ``client``'s query cache and dispose of any values that have a
 * ``[Symbol.dispose]`` method.
 *
 * Notes:
 * - The exported ``SymbolDispose`` can be used if ``Symbol.dispose`` is not
 *   available.
 * - The ``createDisposingObjectUrl`` helper can be used to create a disposable
 *   object URL.
 *
 * For example::
 *
 *   const client = new QueryClient()
 *   queryClientDisposeOnCacheEvict(client)
 *
 *   export const MyComponent = () => {
 *     const imageQuery = useQuery({
 *       queryFn: () => {
 *         const blob = await fetch(...).then(r => r.blob())
 *         // Note: see the ``createDisposingObjectUrl`` helper.
 *         const url = URL.createObjectURL(blob)
 *         return { url, [Symbol.dispose]: () => URL.revokeObjectURL(url) }
 *       },
 *     })
 *     ...
 *   }
 */
export const queryClientDisposeOnCacheEvict = (client: QueryClient) => {
  const cache = client.getQueryCache();
  const needsDispose = new Map<string, Disposable>();

  const add = (queryHash: string, newValue: Disposable) => {
    const existing = needsDispose.get(queryHash);
    if (existing === newValue) {
      return;
    }

    if (existing) {
      remove(queryHash);
    }

    if (newValue?.[SymbolDispose]) {
      // console.info('queryClientDisposeOnCacheEvict: tracking', queryHash);
      needsDispose.set(queryHash, newValue);
    }
  };

  const remove = (queryHash: string) => {
    const oldValue = needsDispose.get(queryHash);
    if (oldValue?.[SymbolDispose]) {
      // console.info('queryClientDisposeOnCacheEvict: disposing', queryHash);
      needsDispose.delete(queryHash);
      try {
        oldValue[SymbolDispose]();
      } catch (err) {
        console.error('queryClientDisposeOnCacheEvict: error disposing', queryHash, err);
      }
    }
  };

  return cache.subscribe((event) => {
    if (event.type === 'added' || event.type === 'updated') {
      const value = cache.get(event.query.queryHash)?.state.data;
      add(event.query.queryHash, value as Disposable);
    }

    if (event.type === 'removed') {
      remove(event.query.queryHash);
    }
  });
};

export const createDisposingObjectUrl = (blob: Blob) => {
  const url = URL.createObjectURL(blob);
  return {
    url,
    [SymbolDispose]: () => URL.revokeObjectURL(url)
  };
};
