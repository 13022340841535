/* eslint-disable no-undef */
import { QueryClientProvider, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { createStandardQueryClient } from './portal/utils/react-query/client';

const queryClient = createStandardQueryClient();

export function initVersionDetector() {
  const element = document.createElement('div');
  element.id = 'app-version-detector';
  document.body.appendChild(element);
  ReactDOM.createRoot(element).render(<AppVersionDetectorContainer />);
}

function AppVersionDetectorContainer() {
  return <>
    <QueryClientProvider client={queryClient}>
      <AppVersionDetector />
    </QueryClientProvider>
  </>;
}

function AppVersionDetector() {
  const versionQuery = useQuery({
    queryKey: ['version'],
    queryFn: async () => {
      const url = __VERSION_URL__;
      console.log('VersionUrl:', url);
      const response = await fetch(url, { cache: 'no-store' });
      return (await response.json()) as string;
    },
    enabled: !import.meta.env.DEV,
    refetchInterval: 1000 * 60,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true
  });

  const newestVersion = versionQuery.data;
  useEffect(() => {
    if (newestVersion) {
      console.log('Newest version:', newestVersion, 'Current version:', __APP_VERSION__);
      if (__APP_VERSION__ !== newestVersion) {
        const shouldRefresh = window.confirm('A newer version is available. Press "Ok" to refresh the page. Press "Cancel" to ignore but you may experience issues.');
        if(shouldRefresh) {
          window.location.reload();
        }
      }
    }
  }, [newestVersion]);

  return null;
}
