import {
  type Activity,
  type ActivityExpense,
  type ActivityTime,
  type AssetWithDetailsResponse,
  type CreateActivityExpenseRequest,
  type UpsertQuoteRequest,
  type Flag,
  type FlagClearRequest,
  type FlagCreateRequest,
  type GetActivityDebriefResponse,
  type GetAssignedToResponse,
  type GetCustomerAndSiteAssetsRequest,
  type UserOnboardingInteraction,
  type ItemInventory,
  type ItemInventoryResponse,
  type Invoice,
  type MyTime,
  type Part,
  type PartPickerRequest,
  type Quote,
  type Sample,
  type UpdateActivityDebriefRequest,
  type AddInteractionRequest,
  type ContactInfo,
  type GetContactsResponse,
  type GetContactsRequest,
  type MyPartsResponse,
  type TimeStatField,
  type ActivityContactInteraction,
  type QuotePart
} from '../Service.types';
import { createContext, useContext } from 'react';
import { type DataTableRequest, type DataTableResponse } from '../../shared-compat/DataTable/hooks';
import type { TimeSpan } from '../../utils/timespan';
import type { IActivityUpload, RequiredActivityUpload } from './portal-api';
import type { StoredFile } from '../../../api/files';
import type { ParentResourceType } from '../../../common/util/files';
import type { BlobWithName } from '../../utils/BlobWithName';
import type { EBypassType } from '../../legacy-portal-api/legacy-api';

export type GetActivitiesRequest = {
  assetId: number | null;
  siteId: number | null;
  daysFilter: number | string | null;
  myWork: boolean;
  statusFilter: string | null;
} & DataTableRequest;

export type GetPartsRequest = {
  assetId: number | null;
  daysFilter: number | string | null;
  partHistory: boolean | null;
} & DataTableRequest;

export type GetQuoteRequest = {
  assetId: number | null;
  siteId: number | null;
} & DataTableRequest;

export type GetSampleRequest = {
  assetId: number | null;
  daysFilter: number | string | null;
} & DataTableRequest;

export type GetFlagsRequest = {
  activityId: string | null;
  daysFilter: number | string | null;
} & DataTableRequest;

export type GetMyTimeRequest = {
  deleteTime?: boolean;
} & DataTableRequest;

export type GetMyPartsRequest = {
  getSurplus: boolean;
  getWip: boolean;
  getInventory: boolean;
} & DataTableRequest;

export type GetLastLoadedReportRequest = {
  assetId: number;
  timeZone: string;
};

export type FileMedia = {
  thumbnail: StoredFile | null;
  files: StoredFile[];
};

export type Customer = {
  id: number;
  customerCrmId: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  notes: string;
};

export interface IRmxServiceApi {
  getCustomerByCrmId(crmId: string): Promise<Customer>;
  // Get Methods
  getActivities(req: GetActivitiesRequest): Promise<DataTableResponse<Activity>>;
  getActivity(activityId: string): Promise<Activity>;
  getActivityTime(activityId: string): Promise<ActivityTime[]>;
  getMyActivities(req: GetActivitiesRequest): Promise<DataTableResponse<Activity>>;
  // dynamic usage in asset-page.js on the asset page in portal. Do not refactor without considering this page.
  getLastLoadedReport(req: GetLastLoadedReportRequest): Promise<Blob>;

  getParts(req: GetPartsRequest): Promise<DataTableResponse<Part>>;
  getPartLibrary(req: GetPartsRequest): Promise<DataTableResponse<ItemInventory>>;
  getPartsByActivityId(activityId: string): Promise<Part[]>;

  getInvoices(assetId?: number): Promise<Invoice[]>;
  getSamples(req: GetSampleRequest): Promise<DataTableResponse<Sample>>;

  getCustomerAndSiteAssets(request: GetCustomerAndSiteAssetsRequest): Promise<AssetWithDetailsResponse[]>; // get site asset with asset modal and serial number
  isAssetMonitored(assetId: number): Promise<boolean>;
  getAssignedToOptions(linkedCrmId: number): Promise<GetAssignedToResponse[]>;

  // Activity Post Methods
  postActivityQuote(activityId: string, quoteId: number): Promise<void>; // posting activity quote
  postActivityExpense(activityId: string, expenseId: number): Promise<void>; // posting activity expense
  postActivityUpload(activityId: string, file: File): Promise<void>; // posting activity upload
  postActivityTime(
    startTime: Date,
    endTime: Date,
    type: string,
    totalTime: Date,
    activityId?: string | number,
    reason?: string,
    entryDate?: Date
  ): Promise<void>; // posting activity time
  postActivitySample(activityId: string, sampleId: number): Promise<void>; // posting activity sample

  // Activity Contact Interaction Methods
  confirmContactInteraction(activityId: string, contactId: number): Promise<void>; // confirming contact interaction
  deleteContactInteraction(activityId: string, contactId: number): Promise<void>; // deleting contact interaction
  getConfirmedContacts(activityId: string): Promise<ActivityContactInteraction[]>; // getting confirmed contacts
  debriefWithContactInteraction(activityId: string, contactId: number): Promise<void>; // debriefing contact interaction
  deleteDebriefWithContactInteraction(contactInteractionId: number): Promise<void>; // removing debrief with contact interaction
  getDebriefWithContacts(activityId: string): Promise<ActivityContactInteraction[]>; // getting debrief with contacts

  // Activity Update Methods
  updateActivityWorkOrderType(activityId: string, workOrderType: string): Promise<void>; // updating activity type
  updateActivityDescription(activityId: string, description: string): Promise<void>; // updating activity description
  updateActivityAvailability(activityId: string, availability: string): Promise<void>; // updating activity availability
  updateActivityAssignedTo(activityId: string | number, assignedTo: string): Promise<void>; // updating activity assigned to
  updateActivityStartDate(activityId: string, startDate: Date): Promise<void>; // updating activity start date
  updateActivityOnSiteTime(activityId: string, onSiteTime: Date): Promise<void>; // updating activity on site time
  updateActivityEstimatedDuration(activityId: string, estimatedDuration: TimeSpan): Promise<void>; // updating activity estimated duration
  updateActivityAssetId(activityId: string, assetId: number): Promise<void>; // updating activity asset id
  updateActivityContactNoResponse(activityId: string, noResponse: boolean): Promise<void>; // updating activity contact no response

  // Activity Delete Methods
  deleteActivityTime(activityId: string, startTime: Date, entryDate: Date): Promise<void>; // deleting activity time

  // Part
  getToteParts(userId: number): Promise<ItemInventoryResponse[]>;
  addTotePartToActivity(activityId: string, ccn: string, selectedQuantity: number): Promise<void>;
  pickPart(req: PartPickerRequest): Promise<void>;
  usedPart(req: PartPickerRequest[]): Promise<void>;
  myParts(req: GetMyPartsRequest): Promise<DataTableResponse<MyPartsResponse>>;

  // Forms
  isSlamSubmitted(activityId: string): Promise<boolean>;

  // Uploads
  getRequiredActivityUploads(dealerId: number, activityType: string): Promise<RequiredActivityUpload[]>;
  getActivityUploads(activityId: string): Promise<IActivityUpload[]>;
  upsertActivityUploads(activityUpload: IActivityUpload, files: Blob[]): Promise<void>;
  deleteActivityUpload(activityUploadId: string): Promise<void>;

  // Flags
  getFlags(req: GetFlagsRequest): Promise<DataTableResponse<Flag>>;
  createFlag(flag: FlagCreateRequest): Promise<void>;
  claimFlag(flagId: number): Promise<void>;
  clearFlag(flag: FlagClearRequest): Promise<void>;

  // Activity Expenses
  createActivityExpense(expense: CreateActivityExpenseRequest, file: File[]): Promise<void>;
  getActivityExpenses(activityId?: string): Promise<ActivityExpense[]>;

  // Activity Quotes
  getQuotes(req: GetQuoteRequest): Promise<DataTableResponse<Quote>>;
  getQuotesByActivityId(activityId: string): Promise<Quote[]>;
  createQuote(quote: UpsertQuoteRequest, file?: File[]): Promise<void>;
  deleteQuote(quoteId: string): Promise<void>;

  getUnsubmittedQuote(activityId: string): Promise<Quote | null>;
  deleteQuoteFile(quoteId: string, fileId: string): Promise<void>;
  createAndSyncQuoteFile(quoteId: string, file: BlobWithName): Promise<void>;

  updateQuoteTitle(params: { activityId: string; quoteId: string | null; title: string }, signal: AbortSignal): Promise<void>;
  updateQuoteNumberOfTechs(params: { activityId: string; quoteId: string | null; numberOfTechs: number | null }, signal: AbortSignal): Promise<void>;
  updateQuoteEstimatedDuration(params: { activityId: string; quoteId: string | null; estimatedDuration: string }): Promise<void>;
  updateQuoteConfirmAsset(params: { activityId: string; quoteId: string | null; confirmAsset: number }): Promise<void>;
  updateQuoteIncludedParts(params: { activityId: string; quoteId: string | null; includedParts: QuotePart[] }): Promise<void>;
  updateQuoteUploads(params: { activityId: string; quoteId: string | null }, file: Blob): Promise<void>;
  updateQuotePrivateNotes(params: { activityId: string; quoteId: string | null; privateNotes: string }, signal: AbortSignal): Promise<void>;

  // Activity Debrief
  getActivityDebrief(activityId: string): Promise<GetActivityDebriefResponse>;
  updateActivityDebrief(request: UpdateActivityDebriefRequest): Promise<void>;
  updateActivityScope(activityId: string, scope: string, signal: AbortSignal): Promise<void>;
  updateActivityConfirmAsset(activityId: string, assetId: number): Promise<void>;
  updateActivityAssetHours(activityId: string, hours: number | null, unknownHoursReason: string | null, signal: AbortSignal): Promise<void>;

  // Activity Time
  getMyTime(req: GetMyTimeRequest): Promise<DataTableResponse<MyTime>>;
  getTimeStats(req: { startDate?: Date; endDate?: Date }): Promise<TimeStatField>;
  deleteNonActivityTime(timeCrmId: string): Promise<void>;

  // Contacts
  getCustomerContacts(customerId: number): Promise<ContactInfo[]>;
  getContacts(request: GetContactsRequest): Promise<GetContactsResponse>;
  upsertContact(customerId: number, contact: ContactInfo): Promise<void>;
  updateActivityPrimaryContact(activityId: string, contactId: number): Promise<void>;
  getActivityContact(activityId: string): Promise<ContactInfo>;

  // Currently not in use... this might change
  // getWorkOrderContacts(workOrderId: number): Promise<ContactInfo[]>;

  addContactToWorkOrder(workOrderId: number, contactId: number): Promise<void>;
  removeContactFromWorkOrder(workOrderContactId: number): Promise<void>;
  updateDebriefWithCommMethod(contactInteractionId: number, communicationMethod: string | null): Promise<void>;

  // User Guide Interaction
  addOnboardingInteraction(request: AddInteractionRequest): Promise<void>;

  /**
   * @returns a list of
   */
  getUserOnboardGuideInfo(): Promise<UserOnboardingInteraction[]>;

  syncedUser(): Promise<boolean>;
  getFiles(parentResourceType: ParentResourceType, parentResourceId: string | null): Promise<FileMedia>;

  // Bypass
  doesAssetHaveBypass(assetId: number, bypassType: EBypassType): Promise<boolean>;
}

const RmxServiceApiContext = createContext<IRmxServiceApi | null>(null);
export const RmxServiceApiProvider = RmxServiceApiContext.Provider;

export function useRmxServiceApi() {
  const rmxServiceContext = useContext(RmxServiceApiContext);
  if (rmxServiceContext === null) {
    throw new Error('useRmxServiceAPi must be used within a RmxServiceApiProvider');
  }
  return rmxServiceContext;
}
